.blog-content {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  line-height: 1.8;
  padding: 0 10px; /* Add padding for better readability */
  max-width: 800px; /* Set a max width for improved readability on larger screens */
}

/* Titles and Headings */
.blog-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: inherit; /* Ensure color matches the mode */
}

.blog-subtitle {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px; /* Adjust for better spacing */
  color: inherit;
}

.blog-section-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px; /* Adjust for better spacing */
  color: inherit;
}

.blog-paragraph {
  font-size: 1.25rem;
  margin-bottom: 30px;
  color: inherit;
}

.blog-list {
  list-style-type: disc;
  margin-left: 25px;
  margin-bottom: 30px;
}

.blog-list li {
  font-size: 1.25rem;
  margin-bottom: 10px; /* Add margin for better spacing between list items */
  color: inherit;
}

/* CTA Button */
.cta-button-container {
  text-align: center;
  margin-top: 50px;
}

.cta-button {
  padding: 14px 28px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  transition:
    background-color 0.3s,
    color 0.3s; /* Smooth transition for hover effects */
}

.cta-button:hover {
  text-decoration: none;
}

/* Dividers */
.blog-divider {
  margin-top: 50px;
  border: none;
  border-top: 2px solid;
}

/* Light Mode */
.light-mode .blog-content {
  color: #4a5568;
}

.light-mode .blog-title,
.light-mode .blog-subtitle,
.light-mode .blog-section-title {
  color: #2c5282;
}

.light-mode .cta-button {
  background-color: #2b6cb0;
  color: #fff;
}

.light-mode .cta-button:hover {
  background-color: #2c5282;
}

.light-mode .blog-divider {
  border-color: #e2e8f0;
}

/* Dark Mode */
.dark-mode .blog-content {
  color: #e2e8f0;
}

.dark-mode .blog-title,
.dark-mode .blog-subtitle,
.dark-mode .blog-section-title {
  color: #63b3ed;
}

.dark-mode .cta-button {
  background-color: #3182ce;
  color: #e2e8f0;
}

.dark-mode .cta-button:hover {
  background-color: #2b6cb0;
}

.dark-mode .blog-divider {
  border-color: #4a5568;
}

/* Responsive Adjustments */
@media (max-width: 500px) {
  .blog-title {
    font-size: 2rem; /* Adjust for smaller screens */
  }

  .blog-subtitle,
  .blog-section-title {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1.25rem;
    padding: 12px;
  }

  .blog-paragraph,
  .blog-list li {
    font-size: 1rem;
  }
}
